@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .label-form {
    @apply block mb-2 text-sm font-medium;
  }
  .input-form {
    @apply block w-full p-3 text-sm rounded-lg outline-none bg-white border border-slate-200 focus:ring-lightcayn focus:ring-1 focus:border-lightcayn placeholder-slate-400;
  }
  .btn-submit {
    @apply w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-lightcayn hover:bg-darkcayn focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightcayn;
  }
  .btn-primary {
    @apply text-white font-medium rounded-3xl text-sm px-5 py-2.5 text-center bg-lightcayn hover:bg-cyan-500 focus:ring-4 focus:ring-lightcayn;
  }
  .btn-secondary {
    @apply text-white font-medium rounded-3xl text-sm px-5 py-2.5 text-center bg-slate-500 hover:bg-black focus:ring-4 focus:ring-slate-800;
  }
  .btn-danger {
    @apply text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-3xl text-sm inline-flex items-center px-5 py-2.5;
  }
  .modal-form {
    @apply fixed w-full h-full top-0 left-0 flex items-center justify-center z-40;
  }
  .modal-form-outside {
    @apply absolute w-full h-full bg-slate-900 opacity-50;
  }
  .modal-form-content {
    @apply w-11/12 md:w-7/12 2xl:w-6/12 text-slate-700 rounded-xl shadow bg-white z-50 overflow-y-auto overflow-x-hidden;
  }
  .input-task {
    @apply block w-full p-3 text-sm rounded-lg outline-none bg-white border border-slate-200 focus:ring-blue-500 focus:ring-1 focus:border-blue-500 placeholder-slate-400;
  }
  .btn-blue {
    @apply bg-blue-600 hover:bg-blue-500 text-white px-6 py-2.5 rounded-2xl text-sm;
  }

  .hover-anim {
    @apply hover:opacity-70 transition-all ease-in-out duration-300 cursor-pointer;
  }
}
